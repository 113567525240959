@import './bootstrap.scss';
@import './utilities.scss';
@import './listingItem.scss';
@import './admin.scss';
@import '../../../node_modules/flag-icon-css/css/flag-icon.min.css';

.main-wrapper {
  padding-top: 7rem;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.navbar-plain {
  * {
    font-size: 0.75rem;
  }
}

.custom-nav {
  .nav-drop:hover .nav-drop-item {
    display: block !important;
  }
  .nav-drop-item a:hover {
    background-color: $primary;
  }
  .nav-drop:hover .plain-drop-item {
    display: block !important;
  }
  .plain-drop-item a:hover {
    background-color: $footer;
  }

  #nav-logged-in {
    top: 0 !important;
    transition: top 0.3s;
  }
  #nav-plain-user {
    top: 0 !important;
  }
}

.bg-nav {
  @include media-breakpoint-up(xs) {
    background: #e5f5ff;
  }
  @include media-breakpoint-up(lg) {
    background: #fff;
  }
}

img.compex-logo {
  max-width: 200px;
}

.plan-card {
  position: relative;
  width: 300px;
  min-height: 450px;
  .select-plan {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;
  }
}

.notifications {
  .notification:hover {
    background-color: #eee !important;
  }
  .bg-unread {
    background-color: #e5f5ff;
  }
}

.custom-home {
  .home-animated {
    .animate-circle-fast {
      position: relative;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(85, 138, 255, 0.17);
      border-radius: 50%;
      height: 148px;
      width: 148px;
      animation-name: beat;
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    .animate-circle-fast::after {
      background-color: rgba(250, 153, 23, 0.12);
      content: '';
      border-radius: 50%;
      position: absolute;
      width: 85px;
      height: 85px;
      top: -25px;
      left: 77.3px;
      animation-name: animate-circle;
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
    .animate-circle {
      position: relative;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(85, 138, 255, 0.17);
      border-radius: 50%;
      height: 148px;
      width: 148px;
      animation-name: beat;
      animation-duration: 9s;
      animation-iteration-count: infinite;
    }
    .animate-circle::after {
      background-color: rgba(250, 153, 23, 0.12);
      content: '';
      border-radius: 50%;
      position: absolute;
      width: 85px;
      height: 85px;
      top: -25px;
      left: 77.3px;
      animation-name: animate-circle;
      animation-duration: 9s;
      animation-iteration-count: infinite;
    }

    @keyframes animate-circle {
      0% {
        top: -25px;
        left: 77.3px;
      }
      25% {
        top: 100px;
        left: 77.3px;
      }
      50% {
        top: 100px;
        left: -25.3px;
      }
      75% {
        top: -25px;
        left: -25px;
      }
      100% {
        top: -25px;
        left: 77.3px;
      }
    }

    .animate-circle-reverse {
      position: relative;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(85, 138, 255, 0.17);
      border-radius: 50%;
      height: 148px;
      width: 148px;
      animation-name: beat;
      animation-duration: 10s;
      animation-iteration-count: infinite;
    }
    .animate-circle-reverse::after {
      background-color: rgba(250, 153, 23, 0.12);
      content: '';
      border-radius: 50%;
      position: absolute;
      width: 85px;
      height: 85px;
      top: -25px;
      left: 77.3px;
      animation-name: animate-circle-reverse;
      animation-duration: 10s;
      animation-iteration-count: infinite;
    }

    @keyframes animate-circle-reverse {
      0% {
        top: -25px;
        left: 77.3px;
      }
      25% {
        top: -25px;
        left: -25px;
      }
      50% {
        top: 100px;
        left: -25.3px;
      }
      75% {
        top: 100px;
        left: 77.3px;
      }
      100% {
        top: -25px;
        left: 77.3px;
      }
    }

    @keyframes beat {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.6);
      }
    }
  }

  .bannerh {
    height: 486px;
  }

  .carousel-caption.text-left {
    text-align: left;
  }

  @include media-breakpoint-up(md) {
    .to-top {
      position: relative;
      z-index: 2;
    }

    .world-map {
      background: url($bg-world-map-webp);
      background-repeat: no-repeat;
      opacity: 0.9;
      width: 903px;
      height: 486px;
      position: absolute;
      top: 15%;
      left: 45%;

      .blink {
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;

        &-america {
          @extend .blink;
          top: 35.92%; /*change this*/
          left: 11.52%; /*change this*/
        }

        &-south-america {
          @extend .blink;
          top: 340px; /*change this*/
          left: 220px; /*change this*/
        }

        &-south-africa {
          @extend .blink;
          top: 69.19%; /*change this*/
          left: 44.05%; /*change this*/
        }

        &-nigeria {
          @extend .blink;
          top: 51.53%; /*change this*/
          left: 40.62%; /*change this*/
        }

        &-ghana {
          @extend .blink;
          top: 50.44%; /*change this*/
          left: 38.3%; /*change this*/
        }

        &-egypt {
          @extend .blink;
          top: 45%; /*change this*/
          left: 45%; /*change this*/
        }

        &-asia {
          @extend .blink;
          top: 45%; /*change this*/
          left: 57%; /*change this*/
        }
      }
      .blink-dot {
        border-radius: 50%;
        background-color: #12388a;
        width: 10px;
        height: 10px;
        animation: blink-strong-anim 1s infinite;
      }
      .blink-pulse {
        background-color: #5b8ae8;
        opacity: 0.75;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        transform: scale(1);
        animation: blink-pulse-anim 2s infinite;
      }

      @keyframes blink-pulse-anim {
        0% {
          opacity: 0.75;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(4);
        }
      }

      @keyframes blink-strong-anim {
        0% {
          opacity: 1;
          transform: scale(0.8);
        }
        50% {
          opacity: 1;
          transform: scale(1.1);
        }
        100% {
          opacity: 1;
          transform: scale(0.8);
        }
      }
    }
  }
}

.investor-listing-item {
  .fixed-width-height {
    width: 293px;
    height: 410px;
  }
}

/* Mail List Container */
.email-wrapper .mail-list-container {
  border-left: 1px solid #e9e9e9;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container a {
  text-decoration: none;
}

.email-wrapper .mail-list-container .mail-list {
  color: #000000;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
}

.email-wrapper .mail-list-container .mail-list.active {
  background-color: #e6e9ed;
  border-bottom: 1px solid #e9e9e9;
}
.email-wrapper .mail-list-container .mail-list:hover {
  background-color: #e6e9ed;
}

.email-wrapper .mail-list-container .mail-list.active:focus,
.mail-list.active:active {
  background-color: #e6e9ed;
}

.email-wrapper .mail-list-container .mail-list.active:active {
  background-color: #e6e9ed;
}
.email-wrapper .mail-list-container .mail-list:last-child {
  border-bottom: none;
}

.email-wrapper .mail-list-container .mail-list .form-check {
  margin-top: 12px;
  width: 11%;
  min-width: 20px;
}

.email-wrapper .mail-list-container .mail-list .content {
  width: 83%;
  padding-left: 0;
  padding-right: 0;
}

.email-wrapper .mail-list-container .mail-list .content .sender-date {
  font-size: 0.75rem;
}
.email-wrapper .mail-list-container .mail-list .content .message_text {
  color: gray;
  margin: 0;
  max-width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-wrapper .mail-list-container .mail-list .content .message_subject {
  margin: 0;
  max-width: 93%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-wrapper .mail-list-container .mail-list .details {
  width: 5.5%;
}

.email-wrapper .mail-list-container .mail-list .details .date {
  text-align: right;
  margin: auto 15px auto 0;
  white-space: nowrap;
}

.email-wrapper .mail-list-container .mail-list .details i {
  margin: auto 0;
  color: #ddd;
}

.email-wrapper .mail-list-container .mail-list .details i.favorite {
  color: #fcd539;
}

.email-wrapper .mail-list-container .mail-list.new_mail {
  background: #e6e9ed;
}

.email-wrapper .mail-list-container .mail-list.new_mail .details .date {
  color: #000000;
}

/* Message Content */
.email-wrapper .message-body .sender-details {
  padding: 20px 15px 0;
  border-bottom: 1px solid #e9e9e9;
  display: -webkit-flex;
  display: flex;
}

.email-wrapper .message-body .sender-details .details {
  padding-bottom: 0;
}

.email-wrapper .message-body .sender-details .details .msg-subject {
  font-weight: 600;
  margin: 0;
}

.email-wrapper .message-body .sender-details .details .sender-email {
  margin-bottom: 8px;
  font-weight: 400;
}

.email-wrapper .message-body .sender-details .details .sender-email i {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 1px 0 7px;
}

.message-content {
  white-space: pre-wrap;
}

.email-wrapper .message-body .attachments-sections ul {
  list-style: none;
  border-top: 1px solid #e9e9e9;
  padding: 30px 15px 20px;
}

.email-wrapper .message-body .attachments-sections ul li {
  padding: 10px;
  margin-right: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}

.email-wrapper
  .message-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a:last-child {
  margin-right: 0;
}
/* purgecss ignore */
.slide-appear {
  opacity: 0;
  z-index: 1;
}
/* purgecss ignore */
.slide-appear.slide-appear-active {
  opacity: 1;
  transition: opacity 300ms linear;
}
/* purgecss ignore */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
/* purgecss ignore */
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 300ms;
}
/* purgecss ignore */
.fade-exit {
  opacity: 1;
}
/* purgecss ignore */
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}
/* purgecss ignore */
.fade-exit-done {
  opacity: 0;
}
/* purgecss ignore */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  z-index: 1;
}
/* purgecss ignore */
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 300ms linear 100ms, transform 300ms ease-in-out 100ms;
}
/* purgecss ignore */
.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}
/* purgecss ignore */
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 150ms linear, transform 150ms ease-out;
}
/* purgecss ignore */
.slide-exit-done {
  opacity: 0;
}

.postlisting {
  overflow-x: hidden;
  .technology {
    width: 12px;
    height: 12px;
    background-color: #00ca93;
  }
  .design {
    width: 12px;
    height: 12px;
    background-color: #0051ca;
  }
  .fashion {
    width: 12px;
    height: 12px;
    background-color: #ca1200;
  }
  .width {
    width: 3px;
  }
  .event {
    width: 12px;
    height: 12px;
    background-color: #fd7900;
  }
  @media (max-width: 1090px) {
    .mobile {
      margin-left: 1px !important;
      margin-right: 10% !important;
      width: 100% !important;
    }
  }
}

#postBody {
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  & .image-style-side,
  & .image-style-align-left,
  & .image-style-align-center,
  & .image-style-align-right {
    max-width: 50%;
  }

  & .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  & .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }
}

#postBody .image.image_resized {
  max-width: 100%;

  display: block;
  box-sizing: border-box;

  & img {
    width: 100%;
  }

  & > figcaption {
    display: block;
  }
}

#postBody .image {
  display: table;
  clear: both;
  text-align: center;

  margin: 1em auto;

  & > img {
    display: block;

    margin: 0 auto;

    max-width: 100%;

    min-width: 50px;
  }
}

#postBody .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
