.cursor-pointer {
  cursor: pointer;
}

.text-lg-1 {
  font-size: 1.5rem;
}

.rounded-1 {
  border-radius: 0.625rem;
}

.rounded-2 {
  border-radius: 20px;
}
.rounded-t-2 {
  border-radius: 20px 20px 0 0;
}

.rounded-3 {
  border-radius: 3rem;
}

.rounded-right-0 {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-left-0 {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.border-thick {
  @extend .border;
  border-width: 3px !important;
}

.shadow.shadow-light {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

$urls: (
  investor-avatar: $investor-avatar,
  business-avatar: $business-avatar,
  franchise-avatar: $franchise-avatar,
  advisor-avatar: $advisor-avatar,
  startup-avatar: $startup-avatar,
  hand-cash-icon: $hand-cash-icon,
  shop-blue-icon: $shop-blue-icon,
  briefcase-icon: $briefcase-icon,
  google-icon: $google-icon,
  facebook-icon: $facebook-icon,
);

@each $key, $url in $urls {
  .bg-#{$key} {
    content: url($url);
    max-width: 0.75rem;
  }

  .bg-paywall-#{$key} {
    content: url($url);
    max-width: 3.5rem;
  }
}

.text-sm-1 {
  font-size: 0.8rem;
}

.text-md-1 {
  font-size: 1.2rem;
}

.border-bottom-2 {
  border-bottom: 3px solid #ddd;
}

.border-top-2 {
  border-top: 3px solid #ddd;
}

.text-sm {
  font-size: 0.75rem;
}

.h-unset {
  height: unset;
}

.custom-home {
  .bg-businessday {
    background: url($bg-businessday) center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 10vh;
    width: 12%;
  }

  .bg-cnbc {
    background: url($bg-cnbc) center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 10vh;
    width: 12%;
  }

  .bg-techcabal {
    background: url($bg-techcabal) center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 10vh;
    width: 12%;
  }

  .bg-how-we {
    background: url($bg-how-we) center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 10vh;
    width: 12%;
  }

  .bg-verified {
    background: url($bg-verified) no-repeat center;
    position: relative;
  }

  .bg-trusted {
    background: url($bg-trusted) no-repeat center;
    background-size: 50%;
    position: relative;
  }

  .bg-safe {
    background: url($bg-safe) no-repeat center;
    background-size: 50%;
    position: relative;
  }

  .bg-buy-sell {
    background: url($other-products-buy-sell) no-repeat left;
    background-size: contain;
    min-width: 50px;
    min-height: 50px;
    position: relative;
  }

  .bg-nigeria-map {
    background: url($other-products-nigeria-map) no-repeat left;
    background-size: contain;
    min-width: 50px;
    min-height: 50px;
    position: relative;
  }
  .bg-search-icon {
    background: url($other-products-search-icon) no-repeat left;
    background-size: contain;
    min-width: 50px;
    min-height: 50px;
    position: relative;
  }
  .bg-starship {
    background: url($other-products-starship) no-repeat left;
    background-size: contain;
    min-width: 50px;
    min-height: 50px;
    position: relative;
  }

  .bg-subscribe {
    background: linear-gradient($bg-subscribe-over, $bg-subscribe-over),
      url($bg-subscribe-webp) no-repeat center;
    background-size: cover;
    height: 60vh;
    position: relative;
  }

  .bg-about {
    background: linear-gradient($bg-about-over, $bg-about-over),
      url($bg-about) no-repeat bottom;
    background-size: cover;
    height: 30vh;
    position: relative;
    background-position: 0% 80%;
  }

  .bg-business {
    background: linear-gradient($bg-subscribe-over, $bg-subscribe-over),
      url($bg-business) no-repeat center;
    background-size: cover;
    min-height: 60vh;
    position: relative;
  }

  .bg-contact {
    background: linear-gradient($bg-about-over, $bg-about-over),
      url($bg-contact) no-repeat bottom;
    background-size: cover;
    height: 30vh;
    position: relative;
    background-position: 0% 32%;
  }

  .bg-compex-x {
    background: linear-gradient($bg-seen, $bg-seen),
      url($bg-compex-x-webp) no-repeat 250% 50%;
    position: relative;
  }

  .bg-video {
    background: url($bg-video-webp) no-repeat bottom;
    background-size: cover;
    height: 60vh;
    position: relative;
  }

  .bg-featured {
    background: url($bg-featured-webp) no-repeat;
    background-position: -25%;
    position: relative;
  }

  .bg-transparent {
    background-color: transparent;
  }

  .bg-compex-logo {
    background: url($bg-compex-white-logo) no-repeat center;
    background-size: contain;
    height: 43px;
    background-position: -14px 0px;
  }
}

.admin-custom {
  .status {
    width: 10px;
    height: 10px;
  }
}

.custom-paywall {
  .custom-relative {
    position: relative;
  }

  .custom-tick {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: -8px;
  }
}

@each $with-pc, $width in $widths {
  .w-#{$with-pc} {
    width: unquote($width + '%') !important;
  }

  .min-w-#{$with-pc} {
    min-width: unquote($width + '%') !important;
  }

  .min-vw-#{$with-pc} {
    min-width: unquote($width + 'vw') !important;
  }

  .max-w-#{$with-pc} {
    max-width: unquote($width + '%') !important;
  }

  .h-#{$with-pc} {
    height: unquote($width + '%') !important;
  }

  .vh-#{$with-pc} {
    height: unquote($width + 'vh') !important;
  }

  .min-vh-#{$with-pc} {
    min-height: unquote($width + 'vh') !important;
  }

  .max-h-#{$with-pc} {
    max-height: unquote($width + '%') !important;
  }
}

@each $hsize, $font-size in $font-sizes {
  .h#{$hsize} {
    font-size: $font-size !important;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .font-size-#{$hsize} {
    font-size: $font-size !important;
  }
}

@each $height, $line-height in $line-heights {
  .line-height-#{$height} {
    line-height: $line-height !important;
  }
}

.shadow-left {
  box-shadow: -2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
    -2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
}
.shadow-right {
  box-shadow: 2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
    2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {
  @each $with-pc, $width in $widths {
    .w-xs-#{$with-pc} {
      width: unquote($width + '%') !important;
    }
    .h-xs-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-xs-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }
  @each $key, $value in $rem-units {
    .min-h-rem-xs-#{$key} {
      min-height: $value !important;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
  @each $with-pc, $width in $widths {
    .w-sm-#{$with-pc} {
      width: unquote($width + '%') !important;
    }
    .h-sm-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-sm-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }
  @each $key, $value in $rem-units {
    .max-w-rem-sm-#{$key} {
      max-width: $value !important;
    }
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
  @each $with-pc, $width in $widths {
    .w-md-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-md-#{$with-pc} {
      height: unquote($width + '%') !important;
    }

    .mrp-md-#{$with-pc} {
      margin-right: unquote($width + '%') !important;
    }
    .pb-md-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
    .min-w-md-#{$with-pc} {
      min-width: unquote($width + '%') !important;
    }
    .min-vh-md-#{$with-pc} {
      min-height: unquote($width + 'vh') !important;
    }
  }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
  @each $with-pc, $width in $widths {
    .w-lg-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-lg-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-lg-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
  }
  @each $key, $value in $rem-units {
    .h-rem-lg-#{$key} {
      height: $value !important;
    }
    .min-h-rem-lg-#{$key} {
      min-height: $value !important;
    }
    .w-rem-lg-#{$key} {
      width: $value !important;
    }
    .min-w-rem-lg-#{$key} {
      min-width: $value !important;
    }
    .min-hw-rem-lg-#{$key} {
      min-width: $value !important;
      min-height: $value !important;
    }
    .hw-rem-lg-#{$key} {
      width: $value !important;
      height: $value !important;
    }
    .max-w-rem-lg-#{$key} {
      max-width: $value !important;
    }
  }
  .shadow-lg-left {
    box-shadow: -2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
      -2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
  }
  .shadow-lg-right {
    box-shadow: 2rem 2rem 2rem -1rem rgba(0, 0, 0, 0.06),
      2rem -2rem 2rem -1rem rgba(0, 0, 0, 0.05) !important;
  }
  .scale-down-lg-9 {
    transform: scale(0.9);
  }

  .scale-down-lg-7 {
    transform: scale(0.75);
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
  @each $with-pc, $width in $widths {
    .w-xl-#{$with-pc} {
      width: unquote($width + '%') !important;
    }

    .h-xl-#{$with-pc} {
      height: unquote($width + '%') !important;
    }
    .pb-xl-#{$with-pc} {
      padding-bottom: unquote($width + '%') !important;
    }
    @media only screen and (max-height: 1000px) {
      .min-vh-xl-#{$with-pc} {
        min-height: unquote($width + 'vh') !important;
      }
      .vh-xl-#{$with-pc} {
        height: unquote($width + 'vh') !important;
      }
    }
  }
}

//xxl
@media only screen and (min-width: 1200px) {
  @each $key, $value in $rem-units {
    .max-w-rem-xxl-#{$key} {
      max-width: $value !important;
    }
  }
}

//4xl
@media only screen and (min-width: 1440px) {
  @each $key, $value in $rem-units {
    .max-w-rem-4xl-#{$key} {
      max-width: $value !important;
    }
  }
}

//5xl
@media only screen and (min-width: 1600px) {
  @each $key, $value in $rem-units {
    .max-w-rem-5xl-#{$key} {
      max-width: $value !important;
    }
  }
}

//6xl
@media only screen and (min-width: 1856px) {
  @each $key, $value in $rem-units {
    .max-w-rem-6xl-#{$key} {
      max-width: $value !important;
    }
  }
}

@each $key, $value in $rem-units {
  .h-rem-#{$key} {
    height: $value !important;
  }
  .min-h-rem-#{$key} {
    min-height: $value !important;
  }
  .w-rem-#{$key} {
    width: $value !important;
  }
  .min-w-rem-#{$key} {
    min-width: $value !important;
  }
  .max-w-rem-#{$key} {
    max-width: $value !important;
  }
  .min-hw-rem-#{$key} {
    min-width: $value !important;
    min-height: $value !important;
  }
  .hw-rem-#{$key} {
    width: $value !important;
    height: $value !important;
  }
}

.position-left-0 {
  left: 0px;
}

.position-bottom-0 {
  bottom: 0px;
}

.custom-center {
  .vh-center-parent {
    max-height: 100% !important;
    height: 100% !important;
    width: 100% !important;
    position: relative;
  }
  .vh-center {
    max-height: 100% !important;
    max-width: 100% !important;
    width: auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.custom-bttn {
  .btn-shrink:active {
    transform: scale(0.95);
  }
}

.plan {
  transition: margin-top 0.5s, width 0.5s, height 0.3s;
  flex: auto;
}
.plan:hover {
  margin-top: 0px !important;
  margin-bottom: 3rem !important;
}

.text-overflow {
  word-break: break-all;
  white-space: normal;
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  background-color: transparent;
}

.button-as-link:hover {
  text-decoration: underline;
  color: #001034 !important;
}

.button-as-link:active {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  background-color: transparent;
}
.button-as-link:focus {
  outline: none;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  background-color: transparent;
}
