@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

$body-bg: #f4f4f4;
$body-color: #333;
$listing-img-height: 163px;
$listing-img-width: 245px;
$darkdash: #131313;
$bg-valuation: '../img/bg-valuation.png';
$verify-dark: #0f0f0f;
$verify-light: #353535;
$bg-subscribe: '../img/bg-subscribe.png';
$bg-subscribe-webp: '../img/bg-subscribe.webp';
$bg-subscribe-over: rgba(15, 15, 15, 0.8);
$subscribe-light: #ececec;
$subscribe-dark: #111;
$bg-verified: '../img/verified-icon.png';
$bg-safe: '../img/safe-icon.webp';
$bg-trusted: '../img/trusted-icon.webp';
$bg-video: '../img/bg-video.png';
$bg-video-webp: '../img/bg-video.webp';
$bg-video-over: rgba(21, 21, 21, 0.67);
$bg-featured: '../img/featured-background-round.svg';
$bg-featured-webp: '../img/featured-background-round.webp';
$bg-seen: rgba(227, 246, 255, 0.33);
$bg-cnbc: '../img/cnbc.png';
$bg-businessday: '../img/businessday.webp';
$bg-techcabal: '../img/techcabal.png';
$bg-how-we: '../img/how-we.png';
$bg-compex-x: '../img/compex-x.png';
$bg-compex-x-webp: '../img/compex-x.webp';
$state-green: #06490b;
$directory-purple: #30004a;
$startup-orange: #aa3105;
$other-products-buy-sell: '../img/other-products-buy-sell.webp';
$other-products-nigeria-map: '../img/other-products-nigeria-map.webp';
$other-products-search-icon: '../img/other-products-search-icon.webp';
$other-products-starship: '../img/other-products-starship.webp';
$bg-landing: #e5f5ff;
$sky-grey: #f9f9f9;
$sky-light: #f0f0f0;
$admin-primary: #194ab4;
$bg-modal: rgba(255, 255, 255, 0.62);
$investor-avatar: '../img/investor-avatar.png';
$business-avatar: '../img/business-avatar.png';
$franchise-avatar: '../img/franchise-avatar.png';
$startup-avatar: '../img/startup-icon.png';
$bg-compex-logo: '../img/compex-logo.svg';
$bg-compex-white-logo: '../img/compex-white-logo.svg';
$footer-shade: #b3b8c3;
$bg-world-map: '../img/world-map.png';
$bg-world-map-webp: '../img/world-map.webp';
$footer: #021439;
$primary: #002880;
$border: #868686;
$about: #171717;
$bg-about: '../img/bg-about.webp';
$bg-about-over: rgba(26, 26, 26, 0.52);
$bg-contact: '../img/contact.webp';
$plus: #1a1a1a;
$details: #1c1b1b;
$bright-primary: #1244b2;
$bg-business: '../img/bg-business.webp';
$business-white: #f2f3f7;
$bg-lighter: rgba(255, 255, 255, 0.65);
$pricing: #505050;
$plan: #151515;
$advisor-avatar: '../img/advisor.webp';
$hand-cash-icon: '../img/hand-cash.webp';
$shop-blue-icon: '../img/shop_blue.webp';
$briefcase-icon: '../img/work.webp';
$dashboard: #fbfbfb;
$google-icon: '../img/google-icon.svg';
$facebook-icon: '../img/facebook-icon.png';

html,
body {
  overflow-x: hidden;
}

video:focus {
  outline: none;
}

$theme-colors: (
  'primary': $primary,
  'footer': $footer,
  'darkdash': $darkdash,
  'covid': #ffcc00,
  'verify-dark': $verify-dark,
  'verify-light': $verify-light,
  'subscribe-light': $subscribe-light,
  'subscribe-dark': $subscribe-dark,
  'seen': $bg-seen,
  'state-green': $state-green,
  'directory-purple': $directory-purple,
  'startup-orange': $startup-orange,
  'landing': $bg-landing,
  'sky-grey': $sky-grey,
  'admin-primary': $admin-primary,
  'modal': $bg-modal,
  'footer-shade': $footer-shade,
  'sky-light': $sky-light,
  'border': $border,
  'body': $body-bg,
  'about': $about,
  'plus': $plus,
  'details': $details,
  'bright-primary': $bright-primary,
  'lighter': $bg-lighter,
  'business-white': $business-white,
  'pricing': $pricing,
  'plan': $plan,
  'dashboard': $dashboard,
);

$widths: (
  0: 0,
  5: 5,
  9a: 9.4,
  10: 10,
  15: 15,
  16: 16,
  20: 20,
  25: 25,
  30: 30,
  38: 38,
  50: 50,
  55: 55,
  56: 56,
  56a: 56.4,
  40: 40,
  60: 60,
  62: 62,
  70: 70,
  72: 72,
  73: 73,
  80: 80,
  85: 85,
  90: 90,
  94: 94,
  99: 99,
  100: 100,
);

$font-sizes: (
  1a: 2.875rem,
  1b: 3rem,
  1c: 2.0625rem,
  1d: 2.625rem,
  1e: 1.375rem,
  1z: 1.5625rem,
  1y: 1.0625rem,
  2a: 1.25rem,
  4a: 1rem,
  5: 0.9375rem,
  7: 0.875rem,
  7y: 0.8125rem,
  7z: 0.6875rem,
  8: 0.625rem,
  8a: 0.75rem,
  9: 0.7rem,
  10: 0.6rem,
  11: 0.5rem,
);

$line-heights: (
  0: 0rem,
  1: 0.2rem,
  2: 0.87rem,
  3: 0.95rem,
  4: 1.5rem,
  5: 1.7rem,
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    2a: (
      $spacer * 0.875,
    ),
    3a: (
      $spacer * 4.8,
    ),
    6: (
      $spacer * 6.5,
    ),
    6a: (
      $spacer * 7.4,
    ),
    7: (
      $spacer * 8.5,
    ),
    8: (
      $spacer * 10.5,
    ),
    9: (
      $spacer * 9.5,
    ),
  ),
  $spacers
);

$rem-units: (
  2: 1.5rem,
  2a: 1.875rem,
  3: 3.5rem,
  3a: 4.2rem,
  6: 6.75rem,
  7: 7.4rem,
  13: 13rem,
  17: 17.625rem,
  20: 20.5rem,
  22: 22rem,
  23: 23rem,
  24: 24rem,
  34: 34rem,
  36: 36rem,
  37: 37rem,
  42: 42rem,
  44: 44.4rem,
  50: 50rem,
  53: 53rem,
  60: 60rem,
  64: 64rem,
  70: 70rem,
  76: 76rem,
  75: 75rem,
  80: 80rem,
  82: 82rem,
  90: 90rem,
  94: 94rem,
  100: 100rem,
  101: 101rem,
  110: 110rem,
  120: 120rem,
  125: 125rem,
  140: 140rem,
);

$font-family-sans-serif: 'Poppins',
  // Safari for OS X and iOS (San Francisco)
    -apple-system,
  // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
  // Windows 'Segoe UI',
    // Android
    'Roboto',
  // Basic web fallback
    'Helvetica Neue',
  Arial, sans-serif,
  // Emoji fonts
    'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-size-base: 0.9rem;
