.listing-item {
  font-size: 0.7rem;

  .card {
    min-width: 255px;
    max-width: 255px;
    min-height: 355px;
    max-height: 355px;
  }

  .listing-item-shadow {
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
}
