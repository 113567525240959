.admin-page {
  .stats {
    .title {
      font-size: 3rem;
    }
    .stat-value {
      font-size: 3rem;
      font-weight: 1000;
    }
  }
}
